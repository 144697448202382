body {
  margin: 0;
  background-color: #f8f8f8;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
  text-align: center;
  color: white;
  margin-bottom: 2em;
  font-size: 1.75em;
}

form {
  align-items: center;
  display: block;
}

.flex {
  display: flex;
  align-items: center;
}

.hide {
  display: none;
}

.link {
  border: none;
  border-bottom: 1px solid #6a8532;
  background-color: white;
  color: #6a8532;
  cursor: pointer;
  padding: 4px 0;
  margin: 0 10px;
}

.login {
  border-radius: 10px;
  background-color: white;
  width: 500px;
  margin: auto;
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
}

.input-container {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

.input-icon {
  border: none;
  border-bottom: 1px solid #999;
}

.input-field {
  width: 100%;
  display: flex;
  border: none;
  border-bottom: 1px solid #999;
  padding: 6px;
  font-family: 'Ubuntu';
  box-sizing: border-box;
  font-size: 1em;
}

.input-field:focus {
  outline-color: #6a8532;
}

.input-submit {
  background-color: #6a8532;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  padding: 15px 20px;
  width: 100%;
  opacity: 0.9;
  font-size: 1.1em;
  font-weight: bold;
  font-family: 'Ubuntu';
}

.input-submit:hover {
  opacity: 1;
}

.errors {
  color: #eb5e28;
}